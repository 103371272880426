import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import Accueil from "./Components/Accueil"
import Kinesitherapeute from "./Components/Kinesitherapeute"
import Rdv from "./Components/Rdv"
import Acces from "./Components/Acces"
import Faq from "./Components/faq"

export default function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to="/accueil" />
        </Route>
        <Route exact path="/accueil" render={(props) => <Accueil {...props} lang='fr'/> }/>
        <Route exact path="/kinesitherapeute" render={(props) => <Kinesitherapeute {...props} lang='fr'/> }/>
        <Route exact path="/rendez-vous" render={(props) => <Rdv {...props} lang='fr'/> }/>
        <Route exact path="/acces" render={(props) => <Acces {...props} lang='fr'/> }/>
        <Route exact path="/foire-aux-questions" render={(props) => <Faq {...props} lang='fr'/> }/>
        <Route exact path="/home" render={(props) => <Accueil {...props} lang='ndls'/> }/>
        <Route exact path="/therapeut" render={(props) => <Kinesitherapeute {...props} lang='ndls'/> }/>
        <Route exact path="/contact" render={(props) => <Rdv {...props} lang='ndls'/> }/>
        <Route exact path="/bereikbaarheid" render={(props) => <Acces {...props} lang='ndls'/> }/>
        <Route exact path="/veelgestelde-vragen" render={(props) => <Faq {...props} lang='ndls'/> }/>
      </Switch>
    </Router>
  );
}
