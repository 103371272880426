import React, { Component, Fragment } from 'react'
import { NavLink as Link, withRouter } from 'react-router-dom';
import {Navbar, NavbarToggler, Collapse, Nav, NavLink} from 'reactstrap';

const fr = {
  'links': [
    { id:1, name: "Accueil", link: "/accueil"},
    { id:2, name: "Kinésitherapeutes", link: "/kinesitherapeute"},
    { id:3, name: "Rendez-vous", link: "/rendez-vous"},
    { id:4, name: "Accès", link: "/acces"},
    { id:5, name: "Faq", link: "/foire-aux-questions"}
  ]
};

const ndls = {
  'title': 'Cabinet de kinésithérapie',
  'links': [
    { id:1, name: "Home", link: "/home"},
    { id:2, name: "Therapeuten", link: "/therapeut"},
    { id:3, name: "Contact", link: "/contact"},
    { id:4, name: "Bereikbaarheid", link: "/bereikbaarheid"},
    { id:5, name: "Faq", link: "/veelgestelde-vragen"}
  ]
};

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.toggleOpenState = this.toggleOpenState.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggleOpenState() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  switchLanguage(){
    let from = this.props.lang === 'fr' ? fr:ndls;
    let to = this.props.lang === 'fr' ? ndls:fr;

    let urlID = from['links'].find((x)=>x.link===this.props.location.pathname).id;
    this.props.history.push(to['links'][urlID-1].link)
  }

  renderLang() {
    if (this.props.lang === 'fr') {
      return <button style={{cursor:"pointer"}} className="languageBtn btn btn-responsive" onClick={this.switchLanguage.bind(this)}>Ndls</button>
    }
    else {
      return <button style={{cursor:"pointer"}} className="languageBtn btn btn-responsive" onClick={this.switchLanguage.bind(this)}>Fr</button>
    }
  }
/*

*/
  render() {
        let navData = this.props.lang === "fr" ? fr : ndls;
        let banner = this.props.lang === "fr" ? "logoFR" : "logoNDLS";
        return (
            <Fragment>
              <header>
              <div className="img-wrapper">
                <img className="img-responsive" alt="Kiné Vertriest" src={"/Images/" + banner + ".png"} style={{"maxWidth":"1200px", "maxHeight":"150px"}}/>
                <div className="img-overlay">
                  {
                      this.renderLang()
                  }
                </div>
              </div>  
              </header>
              <Navbar expand="md" light className="shadow rounded">
          		  <NavbarToggler onClick={this.toggleOpenState} />
          		  <Collapse isOpen={this.state.isOpen} navbar>
                  <Nav className="mx-auto" navbar>
                    { navData['links'].map((x)=>
                      <NavLink key={x.id} tag={Link} to={x.link} className='px-3' >{x.name}</NavLink>  
                    )}
                  </Nav>
                </Collapse>
              </Navbar>
            </Fragment>
        )
    }
}

export default withRouter(Navigation)