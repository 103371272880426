import React from 'react';
import ReactDOM from 'react-dom';


import App from './App';

import './index.css'
import './index_mobile.css'
import '../node_modules/bootstrap/dist/css/bootstrap.css';


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
