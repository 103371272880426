import React, { Component, Fragment } from 'react'
import { Container, Row, Col, Card, CardText, CardBody,CardTitle } from 'reactstrap';

import Footer from '../Common/Footer';
import Navigation from '../Common/Navigation';

export default class Rdv extends Component {
    renderKine() {
        if(this.props.lang === 'fr'){
            return <CardBody>
                    <CardTitle tag="h3">Kinésithérapeutes</CardTitle>
                    <CardText tag="div" style={{"fontSize": "1em"}}>
                            <p>Nous travaillons uniquement sur rendez-vous, vous pouvez nous contacter par téléphone au <a href="tel:+32473317810">0473/31.78.10</a> (Gaëlle) ou <a href="tel:+32476235503">0476/23.55.03</a> (Antoine) en français, néerlandais ou anglais.</p>
                            <p>La thérapie à domicile est uniquement possible si vous êtes dans l'incapacité de vous déplacer au cabinet pour raisons médicales.</p>
                            <p>Si vous êtes dans l'incapacité de vous présentez à un rendez-vous, veuillez prévenir au moins 24h à l'avance. Si le rendez-vous n'est pas annulé à temps, il sera pris en compte.</p>
                    </CardText>
                </CardBody>
        }
        else {
            return <CardBody>
                    <CardTitle tag="h3">Kinesitherapeuten</CardTitle>
                    <CardText tag="div" style={{"fontSize": "1em"}}>
                        <div>
                            <p>Wij werken enkel op afspraak, dit is telefonisch mogelijk via: <a href="tel:+32473317810">0473/31.78.10</a> (Gaëlle) of <a href="tel:+32476235503">0476/23.55.03</a> (Antoine) en staan tot uw beschikking in het Nederlands, Frans en Engels.</p>
                            <p>Een huisbezoek is enkel mogelijk indien u om medische redenen niet naar de praktijk kan komen.</p>
                            <p>Indien u niet aanwezig kan zijn voor een afspraak, gelieve dan minstens 24u op voorhand te annuleren. Indien een afspraak niet tijdig afgezegd wordt, zal dit in rekening gebracht worden.</p>
                        </div>
                    </CardText>
                </CardBody>
        }
    }

    renderToBring() {
        if(this.props.lang === 'fr'){
            return  <CardBody>
                        <CardTitle tag="h3">A prévoir pour la visite</CardTitle>
                        <CardText tag="div">
                            <ul>
                                <li>Carte d'identité/Passeport</li>
                                <li>La prescription du médecin ainsi qu'une vignette de la mutuelle</li>
                                <li>Votre imagerie médicale et/ou les rapports de votre médecin</li>
                                <li>Des vêtements faciles et confortables</li>
                                <li>1 grand essuie</li>                                    
                            </ul>
                        </CardText>
                    </CardBody>
        }
        else {
            return  <CardBody>
                        <CardTitle tag="h3">Mee te nemen</CardTitle>
                        <CardText tag="div">
                            <ul>
                                <li>Identiteitskaart/Paspoort</li>
                                <li>Voorschrift arts + Vignette van de mutualiteit</li>
                                <li>Beeldvorming, verslagen arts</li>
                                <li>Gemakkelijke kledij</li>
                                <li>1 grote handdoek</li>                                    
                            </ul>
                        </CardText>
                    </CardBody>            
        }
    }

    renderSchedule() {
        if(this.props.lang === 'fr'){
            return <CardBody>
                    <CardTitle className="sideTitle"><i className="far fa-clock"></i>Horaires</CardTitle>
                    <CardText className="sideText">
                        <Row>
                            <Col md='6' xs='6'><p>Lundi</p></Col>
                            <Col md='6' xs='6'><p>08h30 – 20h00</p></Col>
                            <Col md='6' xs='6'><p>Mardi</p></Col>
                            <Col md='6' xs='6'><p>08h30 – 18h00</p></Col>
                            <Col md='6' xs='6'><p>Mercredi</p></Col>
                            <Col md='6' xs='6'><p>14h00 – 20h00</p></Col>
                            <Col md='6' xs='6'><p>Jeudi</p></Col>
                            <Col md='6' xs='6'><p>08h30 – 18h00</p></Col>
                            <Col md='6' xs='6'><p>Vendredi</p></Col>
                            <Col md='6' xs='6'><p>08h30 – 18h00</p></Col>
                        </Row>
                    </CardText>
                </CardBody>
        }
        else {
            return <CardBody>
                    <CardTitle className="sideTitle"><i className="far fa-clock"></i>Openingstijden</CardTitle>
                    <CardText className="sideText">
                        <Row>
                            <Col md='6' xs='6'><p>Maandag</p></Col>
                            <Col md='6' xs='6'><p>08u30 – 20u00</p></Col>
                            <Col md='6' xs='6'><p>Dinsdag</p></Col>
                            <Col md='6' xs='6'><p>08u30 – 18u00</p></Col>
                            <Col md='6' xs='6'><p>Woensdag</p></Col>
                            <Col md='6' xs='6'><p>14u00 – 20u00</p></Col>
                            <Col md='6' xs='6'><p>Donderdag</p></Col>
                            <Col md='6' xs='6'><p>08u30 – 18u00</p></Col>
                            <Col md='6' xs='6'><p>Vrijdag</p></Col>
                            <Col md='6' xs='6'><p>08u30 – 18u00</p></Col>
                        </Row>
                    </CardText>
                </CardBody>
        }
    }

    renderContact() {
        if(this.props.lang === 'fr'){
            return <CardBody>
                <CardTitle className="sideTitle">Contact</CardTitle>
                <CardText tag="div" className="sideText">
                    <p><a href="tel:+32473317810"><i className="fas fa-phone"></i> +32473317810</a></p>
                    <p><a href="mailto:gaelle.vertriest@gmail.com"><i className="fas fa-envelope"></i> gaelle.vertriest@gmail.com</a></p>
                </CardText>
            </CardBody>
        }
        else {
            return <CardBody>
                <CardTitle className="sideTitle">Contact opnemen</CardTitle>
                <CardText tag="div" className="sideText">
                    <div>
                    <p><a href="tel:+32473317810"><i className="fas fa-phone"></i> +32473317810</a></p>
                    <p><a href="mailto:gaelle.vertriest@gmail.com"><i className="fas fa-envelope"></i> gaelle.vertriest@gmail.com</a></p>
                    </div>
                </CardText>
            </CardBody>
        }
    }

    render() {
        let lang = this.props.lang;
        return (
            <Fragment>
                <Navigation lang={lang} />
                <Container>
                    <Row className="mt-5">
                        <Col md="8" xs="12" sm="12">
                            <Card className="mb-3">
                                {this.renderKine()}
                            </Card>
                            <Card className="mb-3">
                                {this.renderToBring()}
                            </Card>
                        </Col>
                        <Col md="4" xs="12" sm="12">
                                <Card className="mb-3">
                                    {this.renderSchedule()}
                                </Card>
                                <Card>
                                    {this.renderContact()}
                                </Card>
                        </Col>
                    </Row>
                    <br />
                </Container>
                <Footer />
            </Fragment>
        )
    }
}
