import React, { Component, Fragment } from 'react'
import { Container, Col, Card, CardText, CardBody } from 'reactstrap';

import Footer from '../Common/Footer';
import Navigation from '../Common/Navigation';

import { UncontrolledCarousel } from 'reactstrap';

const items = [
  {
    src: '/Images/slider.jpeg',
    key: '1',
    altText: 'Revalidation Revalidatie Rehabilitation'
  },
  {
    src: '/Images/slider2.jpeg',
    key: '2',
    altText: 'Cabinet Praktijk Practice'
  },
  {
    src: '/Images/slider3.jpeg',
    key: '3',
    altText: 'Neurologique Neurologie Neurology Bobath'
  },
  {
    src: '/Images/slider4.jpeg',
    key: '4',
    altText: 'Exercices Exercises Equilibre Evenwicht Balance Oefentherapie'
  },
  {
    src: '/Images/slider5.jpeg',
    key: '5',
    altText: 'Neutologique Neurologie Neurology rééducation marche gangrevalidatie walking'
  },
  {
    src: '/Images/slider6.jpeg',
    key: '6',
    altText: 'Kinesithérapie Kinesitherapie Physiotherapy'
  }
];

const fr = {
  "title": "Bienvenue au cabinet de kinésithérapie Vertriest à Koekelberg (Basilique)",
  "content": [
    "Le cabinet, au delà des pathologies classiques, se concentre sur la rééducation et la réadaptation neuromoteur. Nous accompagnons au mieux les adultes atteints de lésions neurologiques cérébrales, de la moelle épinière, du système nerveux périphérique et des muscles.", 
    "En collaboration avec chaque patient, une analyse approfondie permet d'établir une thérapie adaptée et appropriée afin de réduire le recul et de récupérer un maximum des facultés. De cette façon, le patient retrouve/garde le meilleur de ses capacités tout en conservant mobilité et autonomie."
  ],
  "subtitle": "Réeducation et réadaptation du type Neurologique chez l'adulte:",
  "subcontent": [
    "Troubles Neurodégénératifs (Sclérose en Plaques, Sclérose latérale Amyotrophique ou ALS, maladie de Parkinson,..)",
    "Accidents Vasculaires Cérébraux",
    "Affection médullaires, à la fois traumatiques (lésion de la moelle épinière) et non-traumatiques",
    "Pathologies Neuromusculaires (troubles du système nerveux périphérique, pied en équin, dystrophies musculaires, Guillain-Barré, ataxie,..)",
    "Récupération suite à une tumeur cérébrale",
    "Autres maladies cérébrales non congénitales",
  ], 
};

const ndls = {
  "title": "Welkom bij Kinesitherapiepraktijk Vertriest te Koekelberg (Basiliek)",
  "content": [
    "Dit is een kinesitherapiepraktijk waar de neuromotorische revalidatie centraal staat. Volwassen personen met aandoeningen van de hersenen, perifeer zenuwstelsel, ruggenmerg en spieren worden zo goed mogelijk begeleid met aangepaste therapie.",
    "Wij streven er naar samen met de patiënten een zo goed mogelijk fysiek herstel te behalen of de achteruitgang zoveel mogelijk tegen te houden zodat de patiënt zijn capaciteiten optimaal kan benutten met maximaal behoud van mobiliteit en autonomie."
  ],
  "subtitle": "Neurologische Revalidatie bij volwassenen:",
  "subcontent": [
    "Neurodegeneratieve aandoeningen zoals Multiple Sclerose, ALS, Parkinson, …",
    "Cerebrovasculaire aandoeningen",
    "Ruggenmergaandoeningen, zowel traumatisch (o.a. dwarslaesies) als niet-traumatisch",
    "Neuromusculaire aandoeningen zoals perifere zenuwletsels (o.a. dropvoet,..), spierdystrofie, Guillain-Barré, ataxie,…",
    "Herstel na hersentumor",
    "Andere neurologische niet-aangeboren hersenaandoeningen,.."
  ], 
};

export default class Accueil extends Component {
  
    render() {
        let lang = this.props.lang;
        let text = lang==='fr' ? fr:ndls;
        return (
            <Fragment>
                <Navigation lang={lang} />
                <Container className="mb-3">
                    <br />
                    <Col>
                    <h1 className="mb-4">{text.title}</h1>
                    </Col>
                    <Col md="8" className="offset-md-2">
                        <UncontrolledCarousel items={items} />
                        <br />
                    </Col>
                    <Col xs="12" sm="12" md="12" className="mb-2">
                      <Card className="h-100">
                        <CardBody className="mx-auto">
                          <CardText tag="div" style={{"fontSize": "1em"}}>
                        { 
                          text.content.map((x, index)=>
                          <p key={index}>{x}</p>  
                        )}
                        </CardText>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col>
                    <p>{text.subtitle}</p>
                    <ul style={{"fontSize": "1em"}}>
                    {
                      text.subcontent.map((x, index)=>
                      <li key={index}>{x}</li>
                      )}
                    </ul>
                    </Col>

                </Container>
                <Footer lang={lang} />
            </Fragment>
        )
    }
}
