import React, { Component, Fragment } from 'react'
import { Container, Col, Row, Card, CardBody, CardText } from 'reactstrap';

import Footer from '../Common/Footer';
import Navigation from '../Common/Navigation';

const gaelleFr = [
        "Parfaitement bilingue, je suis diplômée en 2015 à la VUB - Master en Kinésithérapie. Spécialisation en Rééducation et Réadaptation Neurologique.",
        "Depuis 2015, je travaille au Centre National de la Sclérose en Plaques situé à Melsbroek. Je fais partie d'une équipe de 35 kinésithérapeutes formés et experts en Sclérose en Plaques, ALS, AVC, Parkinsonisme et Ataxie. Cet environnement me permet une évolution continue et l'accès aux nouvelles techniques universitaires.",
        "J'ai eu la chance de pouvoir démarrer mon activité privée à Koekelberg en 2020, que je combine depuis lors à mi-temps avec mon emploi à Melsbroek.",
        "En complément de mon passif professionnel, je suis des formations en continu. Les sujets abordés sont par exemple : la sclérose en plaques, Parkinson, AVC, Vertiges,... Je suis également détentrice d'une certification Pro-Q-Kine reconnue au niveau national. Depuis 2022 je suis reconnue comme thérapeute PWR! Moves (Parkinson) et en possession du certificat pour thérapie Bobath pour adultes (AVC)."
    ];

const antoineFr = [
    "J’ai été diplômé en 2018 de la VUB/KUL/UH/UA - Master en Kinésithérapie. Spécialisation en atteintes Neurologique.",
    "Comme Gaëlle, je travaille au Centre National de la Sclérose en Plaques situé à Melsbroek. Ce centre de rééducation me permet de continuer à me développer graçe aux liens que nous avons avec différents campus universitaires.",
    "Depuis 2018, j’ai combiné ce poste avec d’autres à temps partiel (cabinet et campus universitaire) et en septembre 2021 j’ai rejoins le cabinet de Gaelle.",
    "Je continue à m’informer et me former pour exécuter un travail de qualité. C’est pourquoi j’ai commencé une formation d’osteopathie (2019) et obtiendrai mon diplome DO en 2024. Tout comme ma collègue je detiens un certificat Pro-Q-Kine reconnue au niveau national que je renouvelle chaque année.",
    "En 2022 j'ai également obtenu le certificat de thérapeute Bobath pour adultes (AVC)."
];

const gaelleNdls = [
        "Ik ben afgestudeerd in 2015 aan de VUB als Master in de Kinesitherapie met specialisatie Neuromotorische Revalidatie.",
        "Sinds 2015 ben ik aan het werk in het Nationaal Multiple Sclerose Centrum te Melsbroek. Ik werk er samen met een team van 35 gespecialiseerde kinesitherapeuten met MS-, ALS-, Parkinsonisme-, CVA- en Ataxiepatiënten. Dankzij deze werkomgeving blijf ik mezelf uitdagen, geniet ik van continue bijscholing en ben ik op de hoogte van de laatste behandelmethodes.",
        "In 2020 heb ik de kinesitherapie-praktijk geopend, en combineer deze sindsdien halftijds met mijn job bij het MS-centrum.",
        "Ik probeer me jaarlijks ook bij te scholen dmv opleidingen zoals verdere specialisatie in Multiple Sclerose, Parkinson, CVA, Vertigo,... en ben erkend als kwalitatief kinesitherapeute bij Pro-Q-Kine. Sinds 2022 ben ik een erkende PWR! Moves therapeut (Parkinson) en in het bezit van het Bobath-certificaat voor volwassenen (CVA)."
];

const antoineNdls = [
    "Ik ben afgestudeerd in 2018 aan de VUB/KUL/UH/UA als Master in de Kinesitherapie met specialisatie Neurologische Aandoeningen.",
    "Sindsdien werk ik net als Gaëlle in het Nationaal Multiple Sclerose Centrum te Melsbroek. Dankzij de nauwe contacten die het NMSC met universitaire campussen heeft, kan ik mezelf blijven uitdagen.",
    "Sinds 2018 heb ik deze job gecombineerd met een andere halftijdse job (praktijk en universitaire campus) en sinds september 2021 combineer ik dit met een samenwerking in deze praktijk.", 
    "Kwaliteitsvolle zorg is voor mij zeer belangrijk. Daarom volg ik jaarlijks opleidingen en haal ik in 2024 een diploma in osteopathie. Net als mijn collega ben ik in het bezit van een landelijk erkend Pro-Q-Kine-certificaat dat ik elk jaar vernieuw.",
    "In 2022 heb ik ook het certificaat voor Bobath-therapie bij volwassenen (CVA) behaald."
];

export default class Kinesitherapeute extends Component {
    render() {
        let lang = this.props.lang;
        let gaelleText = lang==='fr' ? gaelleFr:gaelleNdls;
        let antoineText = lang==='fr' ? antoineFr:antoineNdls;
        return (
            <Fragment>
                <Navigation lang={lang} />
                <Container className="mt-3 mb-5">
                    <Row>
                        <Col md='3' className="mt-5"><img src="/Images/profile.jpeg" alt="Gaëlle Vertriest" /></Col>
                        <Col md='9'>
                            <br />
                            <Card className="h-100">
                                <CardBody className="mx-auto">
                                <h1>Gaëlle Vertriest</h1>
                                    <CardText tag="div" style={{"fontSize": "1em"}}>
                                    { 
                                        gaelleText.map((x, index)=>
                                        <p key={index}>{x}</p>  
                                    )}
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col md='3' className="mt-5"><img src="/Images/Antoine.jpeg" alt="Antoine Vanbeylen" /></Col>
                        <Col md='9'>
                            <br />
                            <Card className="h-100">
                                <CardBody className="mx-auto">
                                    <h1>Antoine Vanbeylen</h1>
                                    <CardText tag="div" style={{"fontSize": "1em"}}>
                                    { 
                                        antoineText.map((x, index)=>
                                        <p key={index}>{x}</p>  
                                    )}
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <Footer lang={lang}/>
            </Fragment>
        )
    }
}
