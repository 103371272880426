import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";

export default class Footer extends Component {
    renderAddress() {
        if(this.props.lang === 'fr') {
            return <Link to="/acces"><address><i className="fas fa-map-marker-alt"></i> Avenue de l'Indépendance Belge 105, 1081 Koekelberg</address></Link>
        }
        else {
            return <Link to="/bereikbaarheid"><address><i className="fas fa-map-marker-alt"></i> Belgische Onafhankelijkheidslaan 105, 1081 Koekelberg</address></Link>
        }
    }
    render() {
        return (
            <footer className="font-small pt-2 rounded mt-auto">
                <Container>
                    <Row>
                        <Col md='6' xs='12'>
                            {this.renderAddress()}
                        </Col>
                        <Col md='3' xs='12'>
                            <a href="mailto:gaelle.vertriest@gmail.com"><i className="fas fa-envelope"></i> gaelle.vertriest@gmail.com</a>
                        </Col>
                        <Col md='3' xs='12'>
                            <a href="tel:+32473317810"><i className="fas fa-phone"></i> +32473317810</a>
                        </Col>
                    </Row>
                </Container>
            </footer>
        )
    }
}
