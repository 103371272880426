import React, { Component, Fragment } from 'react'
import { Container, Col, CardTitle, Card, CardBody, CardText } from 'reactstrap';

import Footer from '../Common/Footer';
import Navigation from '../Common/Navigation';

  const fr = [
    {"title": "Tarifs et payements", "content":"Nous sommes déconventionnés, les tarifs sont affichés dans la salle d'attente du cabinet. Le paiement se fait après chaque séance: en liquide ou par bancontact via l'application bancontact sur smartphone (QR Code)."},
    {"title": "Attestations", "content": "Une attestation pour remboursement de la mutuelle sera donnée mensuellement ou à la fin de la prescription après réception du paiement."},
    {"title": "Mobilité réduite", "content": "Le cabinet est accessible pour les patients à mobilité réduite, vous êtes donc bienvenu en chaise roulante ou scooter."},
]
const ndls = [
    {"title":"Tarieven en betaling", "content":"Wij zijn gedeconventioneerd, de tarieven hangen op in de wachtzaal van de praktijk. De betaling gebeurt bij elke sessie: contant of per bancontact via de bancontact app met QR-code op de smartphone." },
    {"title": "Certificaten", "content":"Een getuigschrift voor terugbetaling van de mutualiteit wordt maandelijks of op het einde van het voorschrift meegegeven na ontvangst van de betaling."},
    {"title": "Rolstoelpatiënten", "content":"Ook voor rolstoelpatiënten is de praktijk toegankelijk, er is een hellend vlak ter beschikking zodat de rolstoel of scooter de trede kan overbruggen."}
]
    
export default class faq extends Component {
    render() {
        let lang = this.props.lang;
        let text = lang==='fr' ? fr:ndls;
        return (
            <Fragment>
                    <Navigation lang={lang} />
                    <Container>
                    <br/>
                        { 
                          text.map((x, index)=>

                            <Col key={index} xs="12" sm="12" md="12" className="mb-2">
                                <Card className="h-100">
                                    <CardBody className="mx-auto">
                                        <CardTitle className="cardTitle"><i className={x.icon}></i> {x.title}</CardTitle>
                                        <CardText tag="div" style={{"fontSize": "1em"}}>
                                            <p>{x.content}</p>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        )}
                </Container>
                <Footer lang={lang}/>
            </Fragment>
        )
    }
}
