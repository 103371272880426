import React, { Component, Fragment } from 'react'
import { Container, Row, Col, Card, CardText, CardBody, CardTitle } from 'reactstrap';

import Footer from '../Common/Footer';
import Navigation from '../Common/Navigation';

const fr = [
    { "title": "Addresse", "icon":"fas fa-map-marker-alt", "content": ["Avenue de l'Indépendance Belge 105, 1081 Koekelberg"] },
    { "title": "En voiture", "icon":"fas fa-car", "content": ["Le parking est aisé dans la rue (disque bleu). Pour les patients à mobilité réduite, le cabinet est accessible via une pente. Vous êtes donc bienvenu en chaise roulante ou scooter."]},
    { "title": "En transports", "icon":"fas fa-bus", "content": ["Métro (4 ou 6): Arrêt Simonis (1km)", "Tram (19): Arrêt Besme (450m)", "Bus (53): Arrêt Bastogne (170m)","Bus (20, 49): Arrêt Bastogne (300m)"] }
];

const ndls = [
    { "title": "Adres", "icon":"fas fa-map-marker-alt", "content": ["Belgische Onafhankelijkheidslaan 105, 1081 Koekelberg"] },
    { "title": "Met de auto", "icon":"fas fa-car", "content": ["Er is parkeergelegenheid in de straat (let op: blauwe schijf). Ook voor rolstoelpatiënten is de praktijk toegankelijk, er is een hellend vlak ter beschikking zodat de rolstoel of scooter de trede kan overbruggen."] },
    { "title": "Met openbaar vervoer", "icon":"fas fa-bus", "content": ["Metro (4 of 6): Halte Simonis (1km)", "Tram (19): Halte Besme (450m)", "Bus (53): Halte Bastenaken (170m)", "Bus (20, 49): Halte Bastenaken (300m)"] }
];

export default class Acces extends Component {

    render() {
        let lang = this.props.lang;
        let text = lang === 'fr' ? fr : ndls;
        let ndlMap = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2518.2854402699923!2d4.316509615417924!3d50.862915179534426!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3c3e4681b7e29%3A0x6ae0d69afb9a677f!2sBelgische%20Onafhankelijkheidslaan%20105%2C%201081%20Koekelberg!5e0!3m2!1snl!2sbe!4v1601585142050!5m2!1snl!2sbe";
        let frMap =  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2518.285257390739!2d4.3165096152688855!3d50.86291856544001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3c3e4681b7e29%3A0x6ae0d69afb9a677f!2sAvenue%20de%20l'Ind%C3%A9pendance%20Belge%20105%2C%201081%20Koekelberg!5e0!3m2!1sen!2sbe!4v1609367120477!5m2!1sen!2sbe";
                      
        let mapsUrl = lang === 'fr' ? frMap:ndlMap;
        
        return (
            <Fragment>
                <Navigation lang={lang} />
                <br />
                <Container>
                    <Row>
                        {
                            text.map((x, index) =>
                                <Col key={index} xs="12" sm="12" md="4" className="mb-2">
                                    <Card className="h-100">
                                        <CardBody className="mx-auto">
                                            <CardTitle className="cardTitle"><i className={x.icon}></i> {x.title}</CardTitle>
                                            <CardText tag="div" style={{"fontSize": "1em"}}>
                                                { x.content.map((x, index)=><p key={index}>{x}</p>)}
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Col>
                            )}
                    </Row>
                    <br />
                    <div className="embed-responsive embed-responsive-16by9">
                        <iframe
                            src={mapsUrl}
                            width="600"
                            height="450"
                            className="embed-responsive-item"
                            frameBorder="0"
                            allowFullScreen=""
                            aria-hidden="false"
                            tabIndex="0"
                            title="address"
                        ></iframe>
                    </div>
                    <br />
                </Container>
                <Footer lang={lang} />
            </Fragment>
        )
    }
}
